if (module.hot) {
  module.hot.accept();
}

document.documentElement.className = document.documentElement.className.replace(
  /no-js/,
  'js'
);

import {loadPlugin} from '@js/utils.js';
import '@css/index.scss';
import '!file-loader!@img/paddling-logo.svg';
import '!file-loader!@img/article-fallback.jpg';
import '!file-loader!@img/trip-community-fallback.jpg';
import '!file-loader!@img/trip-outfitter-fallback.jpg';
import '!file-loader!@img/email-logo.jpg';
import '@js/react-habitat.js';
import '@js/embed-locations';

const runInit = ({init}) => init();

// TODO: pluginify
import('@js/uikit.js').then(runInit);
import('@js/addresses.js').then(runInit);
import('@js/resize-trigger.js').then(runInit);
import('@js/embeds/generator.js').then(runInit);

[
  {
    path: 'billing-address.js',
    elements: [document.getElementById('billing-address')],
  },
  {
    path: 'headroom.js',
    elements: [document.getElementById('header')],
  },
  {
    path: 'login-window.js',
    elements: [document.querySelector('.js-login-window')],
  },
  {
    path: 'places.js',
    elements: document.querySelectorAll('[data-places]'),
  },
  {
    path: 'random-child.js',
    elements: document.querySelectorAll('[data-random-child]'),
  },
  {
    path: 'sitesearch.js',
    elements: document.querySelectorAll('[data-sitesearch]'),
  },
].forEach((plugin) => loadPlugin(plugin));
