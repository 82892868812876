import {toggleClass, toggleAttr, toNodes, serialize} from 'fmjs';

export const formatCurrency = (value, stripZeros = true) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  .format(value);

  return stripZeros ? formatted.replace('.00', '') : formatted;
};

export const createURL = (uri, options = {}) => {
  const url = new URL(uri, window.location.origin);

  return Object.assign(url, options);
};

export const loadPlugin = ({
  path,
  elements = [],
  method = 'default',
}) => {
  const els = toNodes(elements);

  if (!els.length) {
    return Promise.resolve('No matching elements found for plugin.');
  }

  return import(
      /* webpackChunkName: "plugin" */
    `@plugins/${path}`
  ).then((importedModule) => {
    els.forEach((el) => importedModule[method](el));
  })
  .catch((err) => {
    // We were logging to Sentry here…
    console.error(err);
  });
};

export const toggleHidden = (element, hide = !element.hidden) => {
  toggleClass(element, 'uk-hidden', hide);
  toggleAttr(element.querySelector('[data-gtm="sponsor"]'), 'data-gtm-visible', !hide);

  return hide;
};

export const setSearchParams = (
  newParams,
  url = new URL(window.location.href),
  transformValue = (value) => serialize(value)
) => {
  const {searchParams} = url;

  Object.entries(newParams).forEach(([key, value]) => {
    searchParams.set(key, transformValue(value));
  });

  url.search = searchParams.toString();

  return url;
};

export const replaceSearchParamsState = (params) => {
  const url = setSearchParams(params);

  if (url.href !== window.location.href) {
    window.history.replaceState(null, '', url.href);
  }
};

export const hitsToMarkers = (hits) => {
  return hits.filter(({_geoloc}) => _geoloc.lat && _geoloc.lng)
  .map((hit) => {
    const {_geoloc, id} = hit;

    return {
      position: Object.values(_geoloc),
      id,
      hit,
    };
  });
};

export const boundsToRefinement = (bounds) => {
  return {
    northEast: bounds.getNorthEast().wrap(),
    southWest: bounds.getSouthWest().wrap(),
  };
};

export const getTimestamp = (date = new Date(), divisor = 1) => Math.floor(date / divisor);
export const getTimestampSeconds = (date = new Date()) => getTimestamp(date, 1000);
