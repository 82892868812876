import React from 'react';
import ReactDOM from 'react-dom';
import ReactHabitat from 'react-habitat';
import Logger from 'react-habitat/lib';

class MyCustomFactory {
  inject(module, props, target) {
    if (target) {
      const childProps = this.getChildProps(target);

      ReactDOM.render(
        React.createElement(module, Object.assign({}, childProps, props || {})),
        target,
      );

    } else {
      Logger.warn('RHW07', 'Target element is null or undefined.');
    }
  }
  dispose(target) {
    if (target) {
      ReactDOM.unmountComponentAtNode(target);
    }
  }

  getChildProps(target, remove = true) {
    const {previousElementSibling} = target;
    const children = previousElementSibling && previousElementSibling.querySelectorAll('[data-prop]') || [];

    return [...children].reduce((obj, el) => {
      if (remove) {
        el.remove();
      }

      return Object.assign(obj, {
        [el.dataset.prop]: el,
      });
    }, {});
  }
}

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const containerBuilder = new ReactHabitat.ContainerBuilder();

    containerBuilder.factory = new MyCustomFactory();

    containerBuilder.registerAsync(() => import('@components/Products.jsx')).as('Products').withOptions({replaceDisabled: true});
    containerBuilder.registerAsync(() => import('@components/Trips.jsx')).as('Trips').withOptions({replaceDisabled: true});
    containerBuilder.registerAsync(() => import('@components/Classifieds.jsx')).as('Classifieds').withOptions({replaceDisabled: true});
    containerBuilder.registerAsync(() => import('@components/Home.jsx')).as('Home').withOptions({replaceDisabled: true});
    containerBuilder.registerAsync(() => import('@components/ProductLinkSearch.jsx')).as('ProductLinkSearch');
    containerBuilder.registerAsync(() => import('@components/ProductSelect.jsx')).as('ProductSelect');
    containerBuilder.registerAsync(() => import('@components/LoginStatus.jsx')).as('LoginStatus');
    containerBuilder.registerAsync(() => import('@components/UgcUpload.jsx')).as('UgcUpload');

    containerBuilder.registerAsync(() => import('@components/maps/Leaflet.jsx')).as('Leaflet');
    containerBuilder.registerAsync(() => import('@components/maps/LocationsMapEmbed.jsx')).as('LocationsMapEmbed');
    containerBuilder.registerAsync(() => import('@components/maps/NewLocationMap.jsx')).as('NewLocationMap');
    containerBuilder.registerAsync(() => import('@components/maps/RadiusMap.jsx')).as('RadiusMap');
    containerBuilder.registerAsync(() => import('@components/maps/RouteMap.jsx')).as('RouteMap');
    containerBuilder.registerAsync(() => import('@components/maps/LocationsMap.jsx')).as('LocationsMap');
    containerBuilder.registerAsync(() => import('@components/maps/LocationDetailMap.jsx')).as('LocationDetailMap');
    containerBuilder.registerAsync(() => import('@components/maps/LocationsMapWithHits.jsx')).as('LocationsMapWithHits');

    this.setContainer(containerBuilder.build());
  }
}

export default new Habitat();
